import {
    useEthers,
  } from '@usedapp/core'

function ConnetWallet() {
    const { activateBrowserWallet, account } = useEthers()
    return (
      <div>
        {!account && <button onClick={() => activateBrowserWallet()}>Connect Wallet</button>}
        { account && <span> Connected! </span> }
      </div>
    )
  }

export function Navigation() {
    return (
      <div className='navigation'>
        <div className='logo'></div>
        <div className='links'>
            <div className='homepage'>Home Page</div>
            <div>Download Models</div>
        </div>
        <ConnetWallet />
      </div>
    )
  }

export default Navigation;