import {Navigation} from './components/Navigation'
import {MintContainer} from './components/MintContainer'

// const config: Config = {
//   readOnlyChainId: Rinkeby.chainId,
//   readOnlyUrls: {
//     [Rinkeby.chainId]: 'https://rinkeby.infura.io/v3/ed247de3f6c642a3ac4a5703c7a84817',
//   },
// }

const description = `Mint your own 3D printable bongs. Each bong is one of a kind, made of a combination of 5 different compontents, each having 10 possible variations. 
Each bong is 3D printable, and only the owner of the NFT can claim the files for printing.`


let a =  "What is this?!"
let b = "A collection of 6969 3D Bongs. Each is one of a kind. Made by a secret artist."

const explainerBlockTexts = [["What is this?!", <div><p>A collection of 6969 3D Bongs.</p> <p>Each is one of a kind. Made by a secret artist.</p></div>],
                             ["Print it in 3D!", <div><p>Just download the files and print! </p> <p> We also recommend some good printers in the US and UK.</p></div>],
                             ["Who are we?", "A collection of 6969 3D Bongs. Each is one of a kind. Made by a secret artist."]]

function ExplainerBlock(props) {
  return(
    <div className='explainerBlock'>
      <div className='explainerCell explainerCellTitle'> {props.title} </div>
      <div className='explainerCell explainerCellDescription'>  {props.description} </div>
    </div>
  );
}

const ExplainerContainer = ({blocks}) => (
  <>
    {blocks.map((blockText) => (
      <ExplainerBlock key={blockText[0]} title={blockText[0]} description={blockText[1]}/>
    ))
    }
  </>
); 

function Body() {
  return (
  <div>
    <div className='title mainTitle'> Bongers </div>
    <h2 className='title secondaryTitle'> Take a deep breath.. </h2>
  </div>
  );
}

export function App() {
  return (
    <div>
        <Navigation /> 
        <div className="aboveFold">
          <Body />
          <MintContainer />
        </div>

        <ExplainerContainer blocks={explainerBlockTexts}/>
    </div>
  );
}


export default App;