import React from 'react';
import { bongersABI } from '../contractABI';
import { utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import 'rc-slider/assets/index.css';

import {
    useEthers,
    useContractFunction,
  } from '@usedapp/core'

let CONTRACT_ADDRESS = '0x93dB62450e98022Dd27AE1c08292d5197727601F';

export class MintContainer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: '1'};
  
      this.handleMinus = this.handleMinus.bind(this);
      this.handlePlus = this.handlePlus.bind(this);

      this.updateCounter = this.updateCounter.bind(this);
      this.onChange = this.onChange.bind(this);

    }
  
    updateCounter(new_quantity) { 
      
      new_quantity = Math.min(new_quantity, 10)
      new_quantity = Math.max(new_quantity, 1)

      this.setState({value: new_quantity});
    }

    handleMinus(event) {
      this.updateCounter(Number(this.state.value) - 1);
    }

    handlePlus(event) {
      this.updateCounter(Number(this.state.value) + 1);
    }

    onChange(event) {
      let num = Number(event.target.value);
      console.log(event.target.value);
      if(!isNaN(num)) {
        this.updateCounter(num);  
      };
    }

    handleSubmit(event) {
      event.preventDefault();
    }

    render() {
      return (
          <div className='mintContainer'>
            <div className="plusMinus">
              <span onClick={this.handleMinus} className="minus">-</span>
                <input type="text" onChange={this.onChange} value={this.state.value}/>
              <span onClick={this.handlePlus} className="plus">+</span>
            </div>

            <MintButton quantity={this.state.value} />
          </div>
      );
    }
  }
  
  function MintButton(props) {
    const { account, chainId, activateBrowserWallet } = useEthers()
  
    const contractInterface = new utils.Interface(bongersABI);
    const address = CONTRACT_ADDRESS;
    const contract = new Contract(address, contractInterface);
    const { state, send } = useContractFunction(contract, 'mint', { transactionName: 'mint' });
  
    const mintQuantity = (etherAmount, quantity) => {
  
      let quantityMul = Number(props.quantity);
      let weiPrice = utils.parseEther(String(etherAmount * quantityMul));
  
      console.log(weiPrice * quantityMul);
      console.log(state);
  
      send(quantity * quantityMul, {value: weiPrice});
    }
  
    return (
      <div>
        <button className="mintButton" onClick={() => mintQuantity(0.042, 1)}> Mint </button>
      </div>
    )
  }
  

  export default MintContainer;